import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import Datetime from 'react-datetime';
import { BreadCrumb, Modal } from "../../../components";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { PATH } from "../../../routers";
import { getLocationList } from "../../../utilities/common";
import { titleComponents } from "../../SnapShotDetail/constants";
import { BASE_URL_ENV, TASK_CATEGORIES } from "../../SnapShot/SnapshotFacilityTasks/constants";
import "./styles.css";
import { toastSuccess } from "./toasts";
import getAxiosWithDefaultHeaders from "../config";
import { useSnapShotUser } from "../../../utilities/helper"
import moment from "moment";


const priorOptions = [
  { value: 'urgent', label: 'Urgent' },
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' },
]

function SnapshotNewTask(props) {
  const locationList = getLocationList();
  let {
    match: {
      params: { site_code },
    },
  } = props;
  const { user } = useSnapShotUser();
  const location = locationList.find((location) => location.location_code === site_code);

  if (!location) {
    props.history.replace("/404");
    return null;
  }

  const [taskCategory, setTaskCategory] = useState(null);
  const [taskType, setTaskType] = useState(null);
  let [taskTypes, setTaskTypes] = useState([]);
  const [unit, setUnit] = useState(null);
  const [units, setUnits] = useState([]);
  const [prior, setPrior] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isDuplicateTaskError, setIsDuplicateTaskError] = useState(false);
  const [duplicateTaskId, setDuplicateTaskId] = useState(null);
  const Axios = getAxiosWithDefaultHeaders();

  useEffect(() => {
    const fetchTaskTypesAndAllUnits = async () => {
      setIsFetching(true);
      try {
        const results = await Promise.all([
          Axios('/api/tasks/task_types'),
          axios.post(`/api/rafa/locations/${location?.id}`, {
            email: user.email,
            token: user.user_token
          })
        ]);
        setTaskTypes(results[0].data);
        setUnits(results[1].data.units);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };
    fetchTaskTypesAndAllUnits();
  }, [site_code]);

  taskTypes = taskTypes.filter((taskTypes) => taskTypes.category === taskCategory?.value);
  const categoryOptions = TASK_CATEGORIES.map((item) => ({ value: item.category, label: item.optionLabel }));
  const taskTypeOptions = taskTypes.map((type) => ({ value: type.id, label: type.title }));
  const unitOptions = units.map((unit) => ({ value: unit.unit_no, label: unit.unit_no }));

  const handleCreateTask = async (e) => {
    e.preventDefault();
    setIsDuplicateTaskError(false);
    setIsLoading(true);
    try {
      const result = await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/tasks`, {
        task_type_id: taskType.value,
        username: user?.full_name,
        site_code: site_code,
        facility_id: location?.id,
        unit_no: unit?.value,
        prior: prior.value,
        due_date: dueDate,
        description: description,
      });
      toastSuccess("Create task successfully!");
      props.history.push(PATH.SNAPSHOT_TASK_DETAILS + `/${site_code}` + `/${result.data.id}`);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.duplicate_open_task_id) {
        setIsDuplicateTaskError(true);
        setDuplicateTaskId(error.response.data.duplicate_open_task_id);
      }
      console.log("Failed to create task: " + error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-light">
        <Helmet>
          <title>Snapshot</title>
        </Helmet>
        <SnapShotHeader />
        <div>
          <div className="row justify-content-between pt-2">
            <div className="col-9 ml-2">
              <BreadCrumb title="Facility Tasks" path={PATH.SNAPSHOT_FACILITY_TASKS + `/${site_code}`} />
            </div>
          </div>
        </div>
        <div className="col-10 mx-auto min-vh-100">
          <div className="row align-items-start justify-content-between">
            <div className="d-flex align-items-center">
              <p className={"title mr-2"}>{location.name}</p>
              <p className={"separator"}>|</p>
              <h1 className={"title-block m-0 mx-1"}>
                {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
              </h1>
            </div>
          </div>
          <div className="row d-flex flex-column mt-4">
            <h2 className="form-title">Create New Task</h2>
            {isFetching ? (
              <div>
                <div className="spinner-border text-primary mt-4 ml-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            {!isFetching ? (
              <form className="form mt-4">
                <div className="form-group">
                  <label htmlFor="categorySelect">Task Category</label>
                  <Select
                    id="categorySelect"
                    value={taskCategory}
                    onChange={(item) => {
                      setTaskCategory(item);
                      setTaskType(null);
                    }}
                    options={categoryOptions}
                  />
                </div>
                {taskCategory?.value === "unit" ? (
                  <div className="form-group">
                    <label htmlFor="unitSelection">Unit Selection</label>
                    <Select id="unitSelection" value={unit} onChange={(item) => setUnit(item)} options={unitOptions} />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="taskType">Task Type</label>
                  <Select
                    id="taskType"
                    value={taskType}
                    onChange={(item) => setTaskType(item)}
                    options={taskTypeOptions}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="taskType">Priority</label>
                  <Select
                    id="prior"
                    value={prior}
                    onChange={(item) => setPrior(item)}
                    options={priorOptions}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="taskType">Due Date</label>
                  <Datetime value={dueDate} onChange={(value) => setDueDate(value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <Modal
                  style={{ maxWidth: 380 }}
                  visible={isDuplicateTaskError}
                  setVisible={() => setIsDuplicateTaskError(false)}
                  title="Duplicate task"
                >
                  <p>A duplicate open task already exists, please add any addition notes to that task</p>
                  <div className="button-group mt-4">
                    <button
                      className="btn btn-main"
                      onClick={() =>
                        props.history.push(PATH.SNAPSHOT_TASK_DETAILS + `/${site_code}` + `/${duplicateTaskId}`)
                      }
                    >
                      Open Task
                    </button>
                  </div>
                </Modal>

                <button
                  className="btn btn-main"
                  onClick={handleCreateTask}
                  disabled={
                    !taskCategory || !taskType || (taskCategory?.value === "unit" && !unit) || !description || (dueDate && !moment(dueDate).isValid()) || isLoading
                  }
                >
                  Create Task
                </button>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default SnapshotNewTask;
