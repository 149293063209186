import React, { useState, useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { BreadCrumb, DropDown, Modal } from "../../../components";
import SnapShotHeader from "../../../components/SnapShotHeader";
import { PATH } from "../../../routers";
import { getLocationList } from "../../../utilities/common";
import { titleComponents } from "../../SnapShotDetail/constants";
import { BASE_URL_ENV, CUSTOMER_FACING_TITLES, dateDiff, FIELD_STAFF_TITLES, getBackgroundColor, getRoleName, MANAGEMENT_TITLES } from "../../SnapShot/SnapshotFacilityTasks/constants";
import "./styles.css";
import { toastError, toastWarning, toastSuccess } from "../SnapshotNewTask/toasts";
import getAxiosWithDefaultHeaders from "../config";
import Dropdown from 'react-bootstrap/Dropdown';
import moment from "moment";
import { useSnapShotUser } from "../../../utilities/helper";
import {FaPaperclip} from "react-icons/fa";
// import { IoIosCloseCircle } from "react-icons/io";

function SnapshotTaskDetails(props) {
  const { user } = useSnapShotUser();
  const locationList = getLocationList();
  let {
    match: {
      params: { site_code, task_id },
    },
  } = props;
  const location = locationList.find((location) => location.location_code === site_code);

  if (!location || isNaN(task_id)) {
    props.history.replace("/404");
    return null;
  }

  const [taskDetails, setTaskDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpenTask, setIsOpenTask] = useState(false);
  const [isDuplicateTaskError, setIsDuplicateTaskError] = useState(false);
  const [duplicateTaskId, setDuplicateTaskId] = useState(null);
  const [isNoteRequired, setIsNoteRequired] = useState(false);
  const [sessionId, setSessionId] = useState(new Date().getTime());
  const Axios = getAxiosWithDefaultHeaders();
  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageInputRef = useRef(null)
  const [selectedImages, setSelectedImages] = useState([])

  const assignToOptions = useMemo(() => {
    const options = [];
    const portfolio = taskDetails?.portfolio;
    if (portfolio) {
      const field_staff_title = portfolio.field_staff_title;
      const customer_facing_title = portfolio.customer_facing_title;
      const management_title = portfolio.management_title;

      if (FIELD_STAFF_TITLES[field_staff_title]) {
        options.push({ value: field_staff_title, label: FIELD_STAFF_TITLES[field_staff_title] });
      }
      if (CUSTOMER_FACING_TITLES[customer_facing_title]) {
        options.push({
          value: customer_facing_title,
          label: CUSTOMER_FACING_TITLES[customer_facing_title]
        })
      };
      if (MANAGEMENT_TITLES[management_title]) {
        options.push({ value: management_title, label: MANAGEMENT_TITLES[management_title] });

      }
    }
    return options
  }, [taskDetails]);

  const navigate = (direction) => {
    const newIndex = currentImageIndex + direction;
    if (newIndex >= 0 && newIndex < taskDetails?.images.length) {
      setCurrentImageIndex(newIndex);
    }
  };

  const resetState = () => {
    setTaskDetails(null);
    setIsLoading(false);
    setNote("");
    setIsModalVisible(false);
    setIsUpdating(false);
    setIsOpenTask(false);
    setIsDuplicateTaskError(false);
    setDuplicateTaskId(null);
  };

  useEffect(() => {
    const fetchTaskDetails = async () => {
      setIsLoading(true);
      try {
        const result = await Axios(`/api/tasks/${task_id}`);
        setIsOpenTask(result.data.status === "open");
        result.data.task_activities = result.data.task_activities.sort(
          (a1, a2) => new Date(a1.created_at) - new Date(a2.created_at)
        );
        setTaskDetails(result.data);
      } catch (error) {
        setTaskDetails(null);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    resetState();
    fetchTaskDetails();
  }, [task_id, sessionId]);

  const handleCloseTask = () => {
    if (!note) {
      toastWarning("Please enter note before closing the task.");
      setIsNoteRequired(true);
      return;
    }
    setIsNoteRequired(false);
    setIsModalVisible(true);
  };

  const handleYes = async () => {
    setIsUpdating(true);
    try {
      await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/task_activities`, {
        task_id,
        status: "closed",
        note: note || undefined,
      });
      props.history.push(PATH.SNAPSHOT_FACILITY_TASKS + `/${site_code}`);
    } catch (error) {
      toastError(error.toString());
    } finally {
      setIsModalVisible(false);
      setIsUpdating(false);
    }
  };

  const handleNo = () => {
    setIsModalVisible(false);
  };

  const handleAddNote = async () => {

    if (!note) {
      toastWarning("Please enter comment!");
      return;
    } 
    try {
      setIsUpdating(true);
      if(selectedImages.length > 0) {
        const headers = { "Content-Type": "multipart/form-data" };
        const formData = new FormData();
        formData.append('task_id', task_id);
        formData.append('note', note);
        
        for(const selectImage of selectedImages) {
          formData.append('attachments[]', selectImage.file, "upload.jpg");
        }
        await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/task_attachments`, formData, {headers});
      } else {
        await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/task_activities`, { task_id, note });
      }
      setSessionId(new Date().getTime());
    } catch (error) {
      toastError(error.toString());
    } finally {
      setIsUpdating(false);
      setSelectedImages([])
    }
  };

  const handleAssignToRole = async (assign_role) => {
    setIsUpdating(true);
    try {
      await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/task_activities`, {
        task_id,
        note: note || undefined,
        role: assign_role,
      });
      setSessionId(new Date().getTime());
    } catch (error) {
      toastError(error.toString());
    } finally {
      setIsUpdating(false);
    }
  };

  const handleReopenTask = async () => {
    setIsUpdating(true);
    try {
      await Axios.post(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/task_activities`, {
        task_id,
        status: "open",
      });
      window.location.reload();
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.duplicate_open_task_id) {
        setIsDuplicateTaskError(true);
        setDuplicateTaskId(error.response.data.duplicate_open_task_id);
      }
      console.log(error.toString());
    } finally {
      setIsUpdating(false);
    }
  };

  const getSourceUserName = (taskDetail) => {
    const source_created_by = taskDetail.source_created_by;
    const source_user_title = taskDetail.source_user_title || '';
    const source_username = taskDetail.source_username || '';
    const source_user_email = taskDetail.source_user_email || '';

    const userTitleLabel = getRoleName(source_user_title);


    if (!source_created_by) return ''
    if (source_created_by === "snapshot") return ` by ${source_username}`;
    return ` by ${userTitleLabel} - ${source_user_email}`;
  };

  const handleCancelAppointment = (appointmentId) => {
    setIsUpdating(true);
    Axios.put(`${BASE_URL_ENV[process.env.REACT_APP_ENV]}/api/appointments/${appointmentId}/cancel`)
      .then((res) => {
        toastSuccess("Appointment cancel successfully.");
        setSessionId(new Date().getTime());
      })
      .catch((error) => {
        toastError("Failed to cancel appointment: " + error.toString());
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  useEffect(() => {
    const pasteHandler = (event) => {
      const items = event.clipboardData?.items
      if (items) {
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            const blob = items[i].getAsFile()
            if (blob && imageInputRef.current) {
              const file = new File([blob], `pasted-image-${Date.now()}.png`, { type: 'image/png' })
              const dataTransfer = new DataTransfer()
              dataTransfer.items.add(file)
              imageInputRef.current.files = dataTransfer.files
              setSelectedImages(images=> [...images, {file, preview: URL.createObjectURL(file), name: file.name}])
              event.preventDefault()
            }
          }
        }
      }
    }
    document.addEventListener('paste', pasteHandler)

    return () => {
      document.removeEventListener('paste', pasteHandler)
    }
  }, [])

  const handleImageUpload = (event) => {
    const files = event.target.files
    if (files) {
      const newImages = Array.from(files).map(file => ({
        file,
        preview: URL.createObjectURL(file),
        name: file.name
      }))
      setSelectedImages(newImages)
    }
  }


  return (
    <>
      <div className="bg-light">
        <Helmet>
          <title>Snapshot</title>
        </Helmet>
        <SnapShotHeader />
        <div>
          <div className="row justify-content-between pt-2">
            <div className="col-9 ml-2">
              <BreadCrumb title="Facility Tasks" path={PATH.SNAPSHOT_FACILITY_TASKS + `/${site_code}`} />
            </div>
          </div>
        </div>
        <div className="col-10 mx-auto min-vh-100">
          <div className="row align-items-start justify-content-between">
            <div className="d-flex align-items-center">
              <p className={"title mr-2"}>{location.name}</p>
              <p className={"separator"}>|</p>
              <h1 className={"title-block m-0 mx-1"}>
                {titleComponents.map((comp) => location[comp.locationKey]).join(", ")}
              </h1>
            </div>
          </div>
          <div className="row d-flex flex-column mt-4">
            {isLoading ? (
              <div>
                <div className="spinner-border text-primary mt-4 ml-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : null}
            {!isLoading && taskDetails ? (
              <>
                <article className="article">
                  <header>
                    <h2>{taskDetails.task_type.category.toUpperCase()}</h2>
                    {taskDetails.unit_no ? <h2 style={{ fontWeight: 700 }}>{taskDetails.unit_no}</h2> : null}
                  </header>
                  <section className="d-flex align-items-center justify-content-between mt-4">
                    <p>
                      <strong>{taskDetails.task_type.title}</strong>
                      <br />
                      {"Created: " +
                        new Date(taskDetails.created_at).toLocaleTimeString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }) +
                        " - " +
                        dateDiff(taskDetails.created_at) +
                        " day(s)"}
                      <br />
                      {"Last updated: " +
                        new Date(taskDetails.updated_at).toLocaleTimeString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }) +
                        " - " +
                        dateDiff(taskDetails.updated_at) +
                        " day(s)"}
                    </p>
                    <div className="role">
                      <div className="thumbnail-gallery">
                        {taskDetails.images.map((image, index) => (
                          <button
                            key={index}
                            onClick={() => {
                              setShowModal(!showModal);
                              setCurrentImageIndex(index);
                            }}
                          >
                            <img
                              src={image}
                              alt={`Thumbnail ${index + 1}`}
                              className="img-fluid"
                              style={{ maxHeight: 100 }}
                            />
                          </button>
                        ))}
                      </div>
                      <div style={{ backgroundColor: getBackgroundColor(taskDetails.role) }} className="avatar">
                        {taskDetails.role?.split("_").map((word) => word[0]).join("").slice(0, 2).toUpperCase()}
                      </div>
                    </div>
                  </section>

                  <hr />
                  <section className="d-flex flex-column mt-2">
                    {taskDetails.task_activities.map((taskDetail) => (
                      <div className="comment mb-2 mt-2 p-3" key={taskDetail.id}>
                        <p className="note">{taskDetail.note}</p>
                        <p className="timestamp">
                          {(!isOpenTask && taskDetail.status === "closed" ? "Closed " : "") +
                            moment(taskDetail.created_at).format('MM/DD/YYYY, hh:mm A') +
                            getSourceUserName(taskDetail)}
                        </p>
                      </div>
                    ))}
                    {isOpenTask ? (
                      <div className="form-group mt-2">
                        <textarea
                          className="form-control"
                          rows={3}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Enter your note"
                        />
                        {isNoteRequired && <div className="text-danger">{"Please enter note before closing the task."}</div>}

                        <button className="btn btn-ghost mt-2 p-1" onClick={() => imageInputRef.current?.click()}>
                          <FaPaperclip style={{color:'#007bff'}} />
                        </button>
                        <input type="file" ref={imageInputRef} onChange={handleImageUpload} accept="image/*" className="d-none" multiple />
                        <div className="mt-2">
                          <p className={`font-weight-500 ${selectedImages.length?"":"d-none"}`}>Selected Images:</p>
                          <div className="thumbnail-gallery" style={{maxWidth: '100%'}}>
                          {selectedImages?.map((image, index) => (
                            <a key={index} href={image.preview} target="_blank" rel="noreferrer">
                              <img
                                src={image.preview}
                                alt={`Thumbnail ${index + 1}`}
                                className="img-fluid"
                                style={{ maxWidth: 150 }}
                              />
                            </a>
                          ))}
                          </div>
                        </div>
                      </div>) : null}
                  </section>

                  <hr />
                  {isOpenTask ? (
                    <>
                      <Modal
                        style={{ maxWidth: 380 }}
                        visible={isModalVisible}
                        setVisible={() => setIsModalVisible(false)}
                        title="Close task"
                      >
                        <p>Are you sure you want to close the task?</p>
                        <div className="button-group mt-4">
                          <button className="btn btn-main" onClick={handleYes} disabled={isUpdating}>
                            Yes
                          </button>
                          <button className="btn btn-main-outline" onClick={handleNo} disabled={isUpdating}>
                            No
                          </button>
                        </div>
                      </Modal>

                      <div className="button-group mb-4">
                        <button className="btn btn-main-outline" onClick={handleCloseTask} disabled={isUpdating}>
                          Close Task
                        </button>
                        {taskDetails.appointment_id ? (
                          <button
                            className="btn btn-main-outline"
                            onClick={() => handleCancelAppointment(taskDetails.appointment_id)}
                            disabled={isUpdating}
                          >
                            Cancel Appointment
                          </button>
                        ) : null}

                        <div className="button-group">
                          <Dropdown className="mr-3 dropup">
                            <Dropdown.Toggle variant="main" bsPrefix="dropdown">
                              {`Assign to ...`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {assignToOptions.map((option) => (
                                <Dropdown.Item
                                  onClick={() => handleAssignToRole(option.value)}
                                  disabled={isUpdating}
                                  bsPrefix="dropdown-item dropdown-item-red"
                                  key={option.value}>
                                  {option.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>

                          <button className="btn btn-main" onClick={handleAddNote} disabled={isUpdating}>
                            Add Note
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Modal
                        style={{ maxWidth: 380 }}
                        visible={isDuplicateTaskError}
                        setVisible={() => setIsDuplicateTaskError(false)}
                        title="Duplicate task"
                      >
                        <p>A duplicate open task already exists, please add any addition notes to that task</p>
                        <div className="button-group mt-4">
                          <button
                            className="btn btn-main"
                            onClick={() =>
                              props.history.push(PATH.SNAPSHOT_TASK_DETAILS + `/${site_code}` + `/${duplicateTaskId}`)
                            }
                          >
                            Open Task
                          </button>
                        </div>
                      </Modal>

                      <div className="button-group mb-4">
                        <button className="btn btn-main ml-auto" onClick={handleReopenTask} disabled={isUpdating}>
                          Reopen Task
                        </button>
                      </div>
                    </>
                  )}
                </article>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Modal visible={showModal} setVisible={setShowModal} title="Cleaning Fee Attach Image">
        <div className="text-center">
          <img
            src={taskDetails?.images?.[currentImageIndex]}
            alt={`Cleaning Fee Attach Image ${currentImageIndex + 1}`}
            style={{ maxHeight: "60vh" }}
            className="img-fluid"
          />
        </div>

        <div className="p-2 text-center">
          Image {currentImageIndex + 1} / {taskDetails?.images.length}
        </div>
        <div className="text-center">
          <span>An image was uploaded by {taskDetails?.source_username}</span>
        </div>

        {/* Navigation Buttons */}
        <div className="d-flex p-4 flex-row justify-content-center align-items-center">
          <button
            title="Prev image"
            className="btn btn-light mr-2"
            onClick={() => navigate(-1)}
            disabled={currentImageIndex === 0}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <button
            title="Next image"
            className="btn btn-light"
            onClick={() => navigate(1)}
            disabled={currentImageIndex === taskDetails?.images.length - 1}
          >
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default SnapshotTaskDetails;
