import React, { useEffect, useState } from "react";
import { getRandomIntWithoutSequential, setLocationList } from "../utilities/common";
import Modal from "./Modal";
import { IoIosLogOut } from "react-icons/io";
import { DropDown } from "../components";
import axios from "axios";
import { localStorageClear, useSnapShotUser } from "../utilities/helper";

const SnapShotHeader = () => {
  const [generateGateCodeVisibleModal, setGenerateGateCodeVisibleModal] = useState(false);
  const [iSwitchPortfolioVisible, setIsSwitchPortfolioVisible] = useState(false);
  const [generateGateCode, setGenerateGateCode] = useState("");
  const { setUser, user, selectedPortfolioId, setSelectedPortfolioId } = useSnapShotUser();
  const portfolios = user?.portfolios || [];
  const portfolioOptions = portfolios.map((item) => ({ value: item.id.toString(), title: item.name }));
  const hideSwitchPortfolio = portfolios.length <= 1;
  const [portId, setPortId] = useState(selectedPortfolioId);

  const handleLogout = () => {
    sessionStorage.clear();
    setUser(null);
    localStorageClear();
    window.location.href = "/login";
  };

  const handleGenerateGateCode = () => {
    const gateCode = getRandomIntWithoutSequential();
    setGenerateGateCode(gateCode);
  };

  useEffect(() => {
    if (generateGateCodeVisibleModal) {
      handleGenerateGateCode();
    }
  }, [generateGateCodeVisibleModal]);

  useEffect(() => {
    axios.post("/api/rafa/locations", {
      email: user.email,
      token: user.user_token,
      portfolioId: selectedPortfolioId,
    }).then((result) => {
      const activeLocations = result.data.filter(e => !e.hide_in_snapshot);
      setLocationList(activeLocations);
    });
  }, []);



  return (
    <nav className="navbar navbar-expand-lg navbar-dark py-3 mx-3 bg-white" id="header">
      <div className="container-fluid">
        <div className="navbar-brand">
          <a href="/">
            <img src="/Snapshot_Logo.svg" alt="logo" style={{ width: "8.5rem", cursor: "pointer" }} />
          </a>
        </div>
        <div>
          <button className="btn btn-ghost" hidden={hideSwitchPortfolio} onClick={() => setIsSwitchPortfolioVisible(true)}>
            <span className="text-primary">Switch Portfolio</span>
          </button>
          <a className="btn btn-indigo mr-2 text-primary" href="/dashboard">
            <img src="/dashboard_icon.svg" alt="logo" className="mr-1" style={{ width: "24px" }} />
            Dashboard
          </a>
          <button className="btn btn-dark" onClick={handleLogout}>
            Logout
            <IoIosLogOut className="ml-2" size={24} />
          </button>
        </div>
      </div>
      <Modal
        title="Generated Gate Code"
        visible={generateGateCodeVisibleModal}
        setVisible={() => {
          setGenerateGateCodeVisibleModal(false);
          setGenerateGateCode("");
        }}
      >
        <input readOnly className="form-control" value={generateGateCode} />
        <button className="btn btn-success my-2" onClick={handleGenerateGateCode}>
          Generate new code
        </button>
      </Modal>
      <Modal
        title="Switch Portfolio"
        visible={iSwitchPortfolioVisible}
        setVisible={() => setIsSwitchPortfolioVisible(false)}
      >
        <div className="mx-2" style={{ maxWidth: 400 }}>
          <DropDown title="Select Portfolio" options={portfolioOptions} onChange={(e) => setPortId(e.target.value)} value={portId}/>
          <button
            className="btn btn-success my-2"
            onClick={() => {
              setSelectedPortfolioId(portId);
              setIsSwitchPortfolioVisible(false);
              window.location.reload();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </nav>
  );
};

export default SnapShotHeader;
